$(document).ready(() => {
  const selectRowCheckbox = $('.select-row');
  const selectPageCheckbox = $('#select-page');
  const selectAllCheckbox = $('#select-all');

  const selectAllLink = $('#select-all-link');
  const clearSelectionLink = $('#clear-selection-link');

  const selectAllMessage = $('.datatable-select-all-message');
  const clearAllMessage = $('.datatable-clear-all-message');

  function isChecked(input) {
    return input.prop('checked');
  }

  function check(input) {
    setChecked(input, true);
  }

  function uncheck(input) {
    setChecked(input, false);
  }

  function toggleCheck(input) {
    setChecked(input, !isChecked(input));
  }

  function setChecked(input, value) {
    if (input.prop('checked') != value) {
      return input.prop('checked', value).change();
    }
  }

  function allRowsAreChecked() {
    return (
      $('input[name="selected_records"]:not([value="all"]):not(:checked)')
        .length == 0
    );
  }

  function show(element) {
    return element.toggle(true);
  }

  function hide(element) {
    return element.toggle(false);
  }

  function updateSelectionCount() {
    if ($('#selection-count').length) {
      const selection_count = $('input.select-row:checked:not([value="all"])')
        .length;
      $('#selection-count').html(selection_count);
    }
  }

  selectRowCheckbox.change(event => {
    setChecked(selectPageCheckbox, allRowsAreChecked());
    updateSelectionCount();
  });

  selectPageCheckbox.change(event => {
    const pageSelector = $(event.target);

    if (isChecked(pageSelector)) {
      check(selectRowCheckbox);
      show(selectAllMessage);
    } else {
      hide(selectAllMessage);
      hide(clearAllMessage);
      uncheck(selectAllCheckbox);
      uncheck(selectRowCheckbox);
    }
  });

  selectAllLink.click(() => {
    check(selectAllCheckbox);
    show(clearAllMessage);
    hide(selectAllMessage);
  });

  clearSelectionLink.click(() => {
    uncheck(selectPageCheckbox);
  });

  const addButton = $('.toggle-selection');

  addButton.click(event => {
    const targetRow = $($(event.target).data('target'));
    const targetInput = targetRow.find('.select-row');

    toggleCheck(targetInput);
    targetRow.toggleClass('selected');
  });

  $('img[data-default-src]').each(function() {
    const defaultSrc = $(this).data('default-src');
    $(this).on('error', function() {
      $(this).attr({ src: defaultSrc });
    });
  });
});
